import { EmbedClient } from './client';
import { VERSION } from '../../core/constants';
import { UserSessionConfig } from '../../core/session';

/**
 * Gets the client instance for the iframe at the given selector.
 */
export async function getClient(selector: string, config?: UserSessionConfig) {
    if (!selector) {
        throw new Error('selector is required');
    }

    const client = new EmbedClient({ selector, config });

    await client.waitUntilReady();

    return client;
}

/**
 * only used for appetize.io frontend since the device is on the page itself and not an iframe
 */
export function getWindowClient(config?: UserSessionConfig) {
    const client = new EmbedClient({ config });

    return client;
}

export const version = VERSION;

export type * from '../../core/api/types/recorder';
export type { DeviceInfo } from '../../core/client';
export type {
    NetworkRequest,
    NetworkResponse,
    SessionConfig,
    SessionInfo,
    AdbConnectionInfo,
    UserSessionConfig,
    SwipeArgs,
    SessionEvents,
    KeyValue,
} from '../../core/session';

declare global {
    interface Window {
        appetize: {
            getClient: typeof getClient;
            version: string;
        };
    }
}
