import { EventEmitter } from '../../EventEmitter';
import { SocketProtocol } from '../types/socket';

export class ClientSocketMapper extends EventEmitter implements SocketProtocol {
    private _socket: SocketProtocol;

    constructor({ socket }: { socket: SocketProtocol }) {
        super();
        this._socket = socket;

        socket.on('*', ({ type, value }) => {
            const mapped = this.mapEmit(type, value);
            const suppressed = mapped === null;

            if (!suppressed) {
                this.emit(mapped.type, mapped.value);
                this.emit('*', mapped);
            }
        });
    }

    private mapEmit(type: string, value: any) {
        switch (type) {
            case 'userError':
                return {
                    type: 'error',
                    value: value,
                };

            case 'concurrentQueue':
                return {
                    type: 'queue',
                    value: {
                        type: 'concurrent',
                        name: value.name,
                        position: value.position,
                    },
                };
            case 'queue':
                return {
                    type: 'queue',
                    value: {
                        type: 'session',
                        position: value.position,
                    },
                };

            // xdoc events
            case 'deviceInfo':
            case 'sessionInfo':
            case 'sessionRequested':
                return {
                    type,
                    value,
                };
        }

        return { type, value };
    }

    private mapSend(type: string, value: any) {
        return { type, value };
    }

    send(event: string, data?: any): Promise<void> {
        const mapped = this.mapSend(event, data);

        return this._socket.send(mapped.type, mapped.value);
    }

    disconnect(): Promise<void> {
        return this._socket.disconnect();
    }
}
