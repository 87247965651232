import { EventEmitter } from '../../EventEmitter';
import { Platform, ScreenBounds } from '../types/recorder/common';
import { AppetizeApp } from '../types/app';
import { DeviceInfo } from '../../client';
import { SessionConfig } from '../../session';
import { SocketProtocol } from '../types/socket';
import { ActionMapper } from './action';
import { ElementMapper } from './element';
import * as InternalRecorderAPI from '../types/recorder/internal';
import * as PublicRecorderAPI from '../types/recorder/public';

/**
 * Wraps an Appetize socket and maps payloads to and from the public API.
 */
export class SessionSocketMapper
    extends EventEmitter
    implements SocketProtocol
{
    platform: Platform;
    screen: ScreenBounds;
    app?: AppetizeApp;
    private _socket: SocketProtocol;

    constructor({
        socket,
        platform,
        screen,
        app,
    }: {
        socket: SocketProtocol;
        platform: Platform;
        screen: ScreenBounds;
        app?: AppetizeApp;
    }) {
        super();
        this._socket = socket;
        this.platform = platform;
        this.screen = screen;
        this.app = app;

        socket.on('*', ({ type, value }) => {
            const mapped = this.mapEmit(type, value);
            const suppressed = mapped === null;

            if (!suppressed) {
                this.handleEvent(mapped.type, mapped.value);
                this.emit(mapped.type, mapped.value);
                this.emit('*', mapped);
            }
        });
    }

    send(event: string, data?: any): Promise<void> {
        const mapped = this.mapSend(event, data);
        return this._socket.send(mapped.type, mapped.value);
    }

    disconnect(): Promise<void> {
        return this._socket.disconnect();
    }

    private handleEvent(type: string, value: any) {
        // update app, screen, platform for mappers
        switch (type) {
            case 'app':
                this.app = value;
                break;
            case 'deviceInfo': {
                const deviceInfo = value as DeviceInfo;
                if (deviceInfo?.screen) {
                    this.screen = deviceInfo.screen;
                }
                break;
            }
            case 'config': {
                const config = value as SessionConfig;
                if (config.platform) {
                    this.platform = config.platform;
                }
                break;
            }
        }
    }

    private mapEmit(type: string, value: any) {
        const actionMapper = new ActionMapper({
            platform: this.platform,
            screen: this.screen,
        });

        const elementMapper = new ElementMapper({
            platform: this.platform,
            screen: this.screen,
        });

        switch (type) {
            case 'debug':
                return {
                    type: 'log',
                    value: value,
                };
            case 'interceptResponse':
                return {
                    type: 'network',
                    value: {
                        type: 'response',
                        ...value,
                    },
                };
            case 'interceptRequest':
                return {
                    type: 'network',
                    value: {
                        type: 'request',
                        ...value,
                    },
                };

            case 'interceptError':
                return {
                    type: 'network',
                    value: {
                        type: 'error',
                        ...value,
                    },
                };

            case 'userError':
                return {
                    type: 'error',
                    value: value,
                };
            case 'userInteractionReceived':
                return {
                    type: 'interaction',
                    value: value,
                };
            case 'countdownWarning':
                return {
                    type: 'inactivityWarning',
                    value: value,
                };
            case 'h264Data':
                return {
                    type: 'video',
                    value: {
                        ...value,
                        codec: 'h264',
                    },
                };

            case 'frameData':
                return {
                    type: 'video',
                    value: {
                        ...value,
                        codec: 'jpeg',
                    },
                };
            case 'audioData': {
                return {
                    type: 'audio',
                    value: {
                        ...value,
                        codec: 'aac',
                    },
                };
            }

            // xdoc events
            case 'deviceInfo':
            case 'sessionRequested':
            case 'orientationChanged':
                return {
                    type,
                    value,
                };
            case 'chromeDevToolsUrl':
                return {
                    type: 'networkInspectorUrl',
                    value,
                };

            case 'recordedAction': {
                return {
                    type: 'action',
                    value: actionMapper.toPublic(value),
                };
            }
            case 'playbackFoundAndSent': {
                const v = value as InternalRecorderAPI.PlayActionResult;

                return {
                    type: 'playbackFoundAndSent',
                    value: {
                        ...v,
                        playback: {
                            ...v.playback,
                            action: v.playback.action
                                ? actionMapper.toPublic(v.playback.action)
                                : undefined,
                        },
                        matchedElements: v.matchedElements?.map((e) => {
                            if (e) {
                                return elementMapper.toPublic(e);
                            }
                        }),
                    },
                } as {
                    type: string;
                    value: PublicRecorderAPI.PlayActionResult;
                };
            }
            case 'playbackError': {
                const v = value as InternalRecorderAPI.PlayActionResult;

                return {
                    type: 'playbackError',
                    value: {
                        ...v,
                        playback: {
                            ...v.playback,
                            action: v.playback.action
                                ? actionMapper.toPublic(v.playback.action)
                                : undefined,
                        },
                        matchedElements: v.matchedElements?.map((e) => {
                            if (e) {
                                return elementMapper.toPublic(e);
                            }
                        }),
                    },
                } as {
                    type: string;
                    value: PublicRecorderAPI.PlayActionErrorResponse;
                };
            }
            case 'uiDump': {
                const appUi = value.ui ?? value.result;
                const springboardUi = value.springboard;

                const mapRecursive = (
                    element: InternalRecorderAPI.FullElement
                ): PublicRecorderAPI.FullElement => {
                    return {
                        ...elementMapper.toPublic(element),
                        children: element.children?.map(mapRecursive),
                    };
                };

                const result: PublicRecorderAPI.AllUI = [];

                if (appUi) {
                    if (this.platform === 'ios') {
                        result.push({
                            type: 'app',
                            appId: this.app?.bundle,
                            children: appUi.map(mapRecursive),
                        });
                    } else {
                        // on android, everything is one tree. in the future they will separate.
                        result.push({
                            type: 'app',
                            children: appUi.map(mapRecursive),
                        });
                    }
                }

                if (springboardUi) {
                    result.push({
                        type: 'app',
                        appId: 'com.apple.springboard',
                        children: springboardUi.map(mapRecursive),
                    });
                }

                return {
                    type: 'uiDump',
                    value: result,
                };
            }

            // suppressed events
            case 'deleteEvent':
                return null;
        }

        return { type, value };
    }

    private mapSend(type: string, value: any) {
        const actionMapper = new ActionMapper({
            platform: this.platform,
            screen: this.screen,
        });

        switch (type) {
            case 'playAction': {
                const payload = value as {
                    id: string;
                    action: PublicRecorderAPI.Action;
                    timeout?: number;
                };
                const noMap = value.__noMap__; // for internal debug, not a public option

                const mappedAction = noMap
                    ? value.action
                    : actionMapper.toInternal(payload.action);

                return {
                    type,
                    value: {
                        ...payload,
                        action: mappedAction,
                    },
                };
            }
        }

        return { type, value };
    }
}
